
import Vue from "vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import {
  exactRule,
  maxLengthRule,
  requiredRule
} from "@/services/helpers/validation-rules";
import store from "@/store";
import { mask } from "vue-the-mask";
import API from "@/api/API";

export default Vue.extend({
  name: "PaymentSuccess",

  components: { FileUploader },

  directives: {
    mask
  },

  data: () => ({
    verificationStep: 2,
    allowedTypes: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/bmp",
      "application/msword"
    ],
    model: {
      document: null,
      ssn: "",
      gender: "m"
    },
    selects: {
      states: [],
      genders: [
        {
          value: "m",
          text: "M"
        },
        {
          value: "f",
          text: "F"
        }
      ]
    },
    errorMessages: {},
    activePickerData: "",
    infoDialog: false,
    showInfoButton: false,
    menu: false as boolean
  }),

  computed: {
    user() {
      return this.$store.getters["user/info"];
    },
    rules() {
      return {
        requiredRule: requiredRule.bind(this),
        maxLengthRule: maxLengthRule.bind(this),
        exactRule: exactRule.bind(this)
      };
    },
    separateUserName() {
      return this.user.name.split("‎ ");
    }
  },

  watch: {
    menu(val) {
      val &&
      setTimeout(() => {
        this.activePickerData = "YEAR";
      });
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const user = store.getters["user/info"];
      const states = await API.location().getStates();

      if (user.documents_verified_at) {
        next("/profile");
      } else {
        next(vm => {
          vm.selects.states = states;
        });
      }
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  mounted() {
    setTimeout(() => {
      this.showInfoButton = true;
    }, 1000);
  },

  methods: {
    save(date: string) {
      (this.$refs.picker as any).activePicker = this.activePickerData;
      (this.$refs.menu as any).save(date);
    },
    async submit() {
      if ((this.$refs.form as Vue).validate()) {
        try {
          await this.$API.user().uploadDocuments(this.model);
          (this.$refs.form as Vue).removeGuard();
          await this.$router.push(`/`);
          await this.$store.dispatch(
            "alert/showSuccess",
            "Documents successfully uploaded!"
          );
        } catch (e) {
          if (e.hasOwnProperty("errors")) {
            this.errorMessages = e.errors;
            setTimeout(() => {
              this.errorMessages = {};
            }, 3000);
          }
          await this.$store.dispatch("alert/showError", e.message);
        }
      }
    }
  }
});
